import gitHubLogo from './resources/GITHUB.svg';
import mediumLogo from './resources/MEDIUM.svg';
import twitterLogo from './resources/TWITTER.svg';
import discordLogo from './resources/discord-footer-logo.svg';
export const PAGE_COUNT = 20;
export const PAGE_SIZE = 6000;

export const Support = 'support@swingby.network';
export const HowItWorks = 'https://skybridge-docs.swingby.network/how-it-works';
export const SwingbyDocs = 'https://skybridge-docs.swingby.network/';
export const Skybridge = 'https://skybridge-testnet.swingby.network/';
export const TermsOfUse = 'https://docs.swingby.network/terms.pdf';

export const Links = [
  { link: 'https://swingby.network/en', description: 'Home' },
  { link: TermsOfUse, description: 'Terms of Use' },
  {
    link: 'https://docs.swingby.network/privacy-policy.pdf',
    description: 'Privacy Policy',
  },
];

export const Media = [
  {
    link: 'https://discord.com/invite/WV8H9jc',
    src: discordLogo,
    alt: 'discord',
  },
  {
    link: 'https://twitter.com/swingbyprotocol/',
    src: twitterLogo,
    alt: 'twitter',
  },
  {
    link: 'https://swingby.medium.com/',
    src: mediumLogo,
    alt: 'medium',
  },
  {
    link: 'https://github.com/SwingbyProtocol/',
    src: gitHubLogo,
    alt: 'github',
  },
];

export const API_ENDPOINT = process.env.REACT_APP_API as string;
export const PRIZE = '1.00 BTC';

// Memo: 2nd Mar '20 2AM UTC
export const START_DATE = 1583114400;
// Memo: 13th Apr '20 2AM UTC
export const END_DATE = 1586743200;
