import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import { PAGE_COUNT } from "../constants";
import firstRank from "../resources/FIRST_RANK.svg";
import secondsRank from "../resources/SECONDS_RANK.svg";
import thirdRank from "../resources/THIRD_RANK.svg";
import { LeaderBoardContainer } from "../styles/customDiv";
import { AddressP } from "../styles/customP";
import { RankingObjectArray } from "../types/camp";
import { addComma } from "../utils/addComma";

interface Props {
  ranking: RankingObjectArray;
  btcPrice: number;
}

const LeaderBoard: React.FC<Props> = (props: Props) => {
  const { ranking, btcPrice } = props;

  // Memo: For debug
  // const mockRanking = MockArray;

  const [page, setPage] = useState<number>(0);

  const prevItemsCount = PAGE_COUNT * page;
  const total = ranking.length;
  const txsLength = Math.min(ranking.length - prevItemsCount, PAGE_COUNT);

  const indexOfFirstTransactions = prevItemsCount + 1;
  const indexOfLastTransactions = prevItemsCount + txsLength;

  const isDisabledGoBack = indexOfFirstTransactions === 1;
  const isDisabledGoNext = txsLength < PAGE_COUNT;

  const currentPageItems = ranking.slice(
    indexOfFirstTransactions - 1,
    indexOfLastTransactions
  );

  const PagingText: JSX.Element = (
    <p>
      {indexOfFirstTransactions} - {indexOfLastTransactions} of {total}
    </p>
  );

  const pagination = (
    <>
      <div className='page'>{PagingText}</div>
      <div className='forward'>
        <Icon
          name='angle left'
          className='forward-icon'
          size='large'
          onClick={() => setPage(page - 1)}
          disabled={isDisabledGoBack}
        />

        <Icon
          name='angle right'
          className='forward-icon'
          size='large'
          onClick={() => setPage(page + 1)}
          disabled={isDisabledGoNext}
        />
      </div>
    </>
  );

  return (
    <LeaderBoardContainer>
      <h2>Ranking Board</h2>
      <div className='list'>
        <div className='pagination'>{pagination}</div>
        <div className='description'>
          <p>Rank</p>
          <p>Wallet Address</p>
          <div />
          <p>Trading Volume (BTC)</p>
          <div />
          <p>Trading Volume (USD)</p>
        </div>

        {currentPageItems.map((record, index) => {
          if (index === 0 && page === 0) {
            return (
              <div className='description listing'>
                <div className='crown'>
                  <p>{page * PAGE_COUNT + index + 1}</p>
                  <img
                    src={firstRank}
                    alt='first-rank'
                    className='first-rank'
                  />
                </div>
                <AddressP>{record.address}</AddressP>
                <div />
                <p>{addComma(record.volume, 2)}</p>
                <div />
                <p>{addComma(record.volume * btcPrice, 0)}</p>
              </div>
            );
          } else if (index === 1 && page === 0) {
            return (
              <div className='description listing'>
                <div className='crown'>
                  <p>{page * PAGE_COUNT + index + 1}</p>
                  <img
                    src={secondsRank}
                    alt='seconds-rank'
                    className='seconds-rank'
                  />
                </div>
                <AddressP>{record.address}</AddressP>
                <div />
                <p>{addComma(record.volume, 2)}</p>
                <div />
                <p>{addComma(record.volume * btcPrice, 0)}</p>
              </div>
            );
          } else if (index === 2 && page === 0) {
            return (
              <div className='description listing'>
                <div className='crown'>
                  <p>{page * PAGE_COUNT + index + 1}</p>
                  <img
                    src={thirdRank}
                    alt='third-rank'
                    className='third-rank'
                  />
                </div>
                <AddressP>{record.address}</AddressP>
                <div />
                <p>{addComma(record.volume, 2)}</p>
                <div />
                <p>{addComma(record.volume * btcPrice, 0)}</p>
              </div>
            );
          } else {
            return (
              <div className='description listing'>
                <p>{page * PAGE_COUNT + index + 1}</p>
                <AddressP>{record.address}</AddressP>
                <div />
                <p>{addComma(record.volume, 2)}</p>
                <div />
                <p>{addComma(record.volume * btcPrice, 0)}</p>
              </div>
            );
          }
        })}
        <div className='pagination'>{pagination}</div>
      </div>
    </LeaderBoardContainer>
  );
};

export default LeaderBoard;
