import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-scroll';
import { PRIZE } from '../constants';
import bar from '../resources/BAR.svg';
import leftDecoration from '../resources/LEFT-DECORATION.svg';
import line from '../resources/LINE.svg';
import rightDecoration from '../resources/RIGHT-DECORATION.svg';
import swapCoin from '../resources/SWAP_COIN.svg';
import trophy from '../resources/TROPHY.svg';
import { CampaignContainer } from '../styles/customDiv';
import { StatsAmountP, StatsTitleP } from '../styles/customP';
import { PrizeSpan, WinSpan } from '../styles/customSpan';
import { RankingObjectArray } from '../types/camp';
import Appreciation from './Appreciation';
import Header from './Header';
import LeaderBoard from './LeaderBoard';
import Rule from './Rule';

interface Props {
  participants: number;
  trades: number;
  volume: number;
  ranking: RankingObjectArray;
  btcPrice: number;
}

const Campaign: React.FC<Props> = (props: Props) => {
  const { participants, trades, volume, ranking, btcPrice } = props;
  const COUNT_UP_START = 1;
  const COUNT_UP_DURATION = 5;

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <CampaignContainer>
      <div className='wrapper'>
        <div className='container'>
          <Header />
          <div className='title-row'>
            <div className='title'>
              <img
                src={leftDecoration}
                alt='left-decoration'
                className='left-decoration'
              />
              <img src={trophy} alt='trophy' className='trophy' />
              <h1>TSVC</h1>
              <img
                src={rightDecoration}
                alt='right-decoration'
                className='right-decoration'
              />
            </div>
            <p>Token Swap Volume Competition</p>
          </div>

          <Appreciation />

          <div className='prize'>
            <img src={swapCoin} alt='swap-coin' className='swap-coin' />
            <Link to='rule-id' smooth={true} offset={0} duration={900}>
              <h1>How to Join TSVC?</h1>
            </Link>
            <WinSpan>WIN</WinSpan>
            <PrizeSpan> {PRIZE}</PrizeSpan>
            <img src={line} alt='line' className='line' />
            <div className='rank'>
              <div className='rank-prize'>
                <p>#1 &nbsp;&nbsp;0.5 BTC</p>
                <p>#2 &nbsp;&nbsp;0.2 BTC</p>
                <p>#3 &nbsp;&nbsp;0.1&nbsp; BTC</p>
              </div>
              <p>#4~#10 &nbsp;&nbsp;0.02 BTC</p>
              <p>Spot prize pool &nbsp;&nbsp;0.06 BTC</p>
            </div>
          </div>

          <div className='stats-row' ref={ref}>
            <h2>Contest Stats</h2>
            <span>Opening 2020/03/02 ~ 2020/4/13</span>
            <div className='stats-container'>
              <div className='stats-column'>
                <StatsTitleP>PARTICIPANTS</StatsTitleP>
                <CountUp
                  start={inView ? COUNT_UP_START : participants}
                  end={participants}
                  duration={COUNT_UP_DURATION}>
                  {({ countUpRef }) => <StatsAmountP ref={countUpRef} />}
                </CountUp>
              </div>
              <div className='stats-column'>
                <StatsTitleP>TRADES</StatsTitleP>
                <div className='stats-column-center'>
                  <img src={bar} alt='bar' className='bar' />
                  <CountUp
                    start={inView ? COUNT_UP_START : trades}
                    end={trades}
                    duration={COUNT_UP_DURATION}
                    separator=','>
                    {({ countUpRef }) => <StatsAmountP ref={countUpRef} />}
                  </CountUp>
                  <img src={bar} alt='bar' className='bar' />
                </div>
              </div>
              <div className='stats-column'>
                <StatsTitleP>VOLUME</StatsTitleP>
                <CountUp
                  start={inView ? COUNT_UP_START : volume}
                  end={volume}
                  duration={COUNT_UP_DURATION}
                  separator=','>
                  {({ countUpRef }) => <StatsAmountP ref={countUpRef} />}
                </CountUp>
                <CountUp
                  start={inView ? COUNT_UP_START : volume * btcPrice}
                  end={volume * btcPrice}
                  duration={COUNT_UP_DURATION}
                  separator=','>
                  {({ countUpRef }) => (
                    <span className='usd' ref={countUpRef} />
                  )}
                </CountUp>
                <span className='usd'> USD</span>
              </div>
            </div>
          </div>
          <LeaderBoard ranking={ranking} btcPrice={btcPrice} />
        </div>
      </div>
      <Rule />
    </CampaignContainer>
  );
};

export default Campaign;
