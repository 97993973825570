import React from "react";
import { RuleContainer } from "../styles/customDiv";

interface Props {}

const Rule: React.FC<Props> = () => {
  return (
    <RuleContainer id="rule-id">
      <div className="wrapper">
        <h2>How to Join TSVC?</h2>
        <div className="container">
          <div className="guide-line">
            <p>
              Use
              <a
                href="https://swingbynetwork.typeform.com/to/ViVwTu"
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                this{" "}
              </a>
              form to register your email--so that we can contact you if you
              win--and your testnet BTC and testnet Bitcoin on Binance chain
              wallet addresses.
            </p>
          </div>
          <div className="guide">
            <div className="guide-line">
              <p>
                Follow Swingby on
                <a
                  href="https://twitter.com/SwingbyProtocol"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  Twitter{" "}
                </a>
                and send us a tweet that says,
                <span className="hash"> “Free #bitcoin”</span>. If you tag a
                friend in the tweet you'll become eligible to receive up to 0.06
                BTC in spot prizes throughout the competition. If your friend
                does the same, your chances of winning will be increased by 2x!
              </p>
            </div>
            <div className="guide-line">
              <p>
                And finally, start swapping your BTC/BTC.B! The winner of the
                contest will be the individual who generates the most volume
                over the Skybridge within a 6-week duration. Prizes will be
                awarded to the ten people who generate the most trading volume.
                <br />
                <a
                  href="https://faucet.swingby.network/"
                  className="faucet-link"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  How to Get (BTC.B) (Testnet Bitcoin on Binance Chain)
                </a>
              </p>
            </div>
          </div>
          <div className="join-step">
            <div className="to-receive">
              <p>
                To receive BTC.B so you can begin executing BTC.B/BTC swaps
                follow these directions:
              </p>
            </div>
            <div className="step">
              <p>
                1. Make sure you have a cryptocurrency wallet that supports
                testnet BTC.B or BTC !
              </p>
              <p>
                2. Head over to Swingby’s BTC.B/BTC faucet:
                <a
                  href="https://faucet.swingby.network/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  https://faucet.swingby.network/
                </a>
              </p>
              <p>3. Enter your BTC.B/BTC wallet address</p>
              <p>
                4. After you enter your address, you will receive a randomly
                generated code.
              </p>
              <p>
                5. Take this code, and post it in the Swingby Telegram Group:
                <br className="br" />
                <a
                  href="https://t.me/Swingby"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  https://t.me/Swingby
                </a>
              </p>
              <p>
                6. Wait for the BTC.B/BTC to hit your wallet! Once it has, send
                a message in Telegram that says ‘received.’{" "}
              </p>
              <p>7. Begin trading!</p>
            </div>
            <div className="bonus">
              <div className="guide-line">
                <p>
                  <p className="bonus-title">*BONUS* </p>
                  Tag a friend in your
                  <span className="hash"> “Free #bitcoin” </span>
                  tweet! If you do this, you will become eligible to receive up
                  to 0.06 BTC in spot prizes throughout the competition, if your
                  friend does the same, your chances of winning will be
                  increased by 2x!
                </p>
              </div>
              <div className="guide-line">
                <p>
                  All spot prize competitions will be announced via Twitter and
                  Telegram
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RuleContainer>
  );
};

export default Rule;
