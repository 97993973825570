// @ts-ignore
export const RankingResult = [
  {
    address: 'tbnb1dq566hvqgf6gjnq8y5p4cp9suaxf7zkmslcrkf',
    volume: 397224.2227474706,
  },
  {
    address: 'tbnb1ph4y347td7hez04935de8ash9zchypznat0hk9',
    volume: 317543.5715492799,
  },
  {
    address: 'tbnb1fvuh5ms97kltq9j9f30hmeeqw857yhnka6fkrk',
    volume: 304602.51581080013,
  },
  {
    address: '2NAXTd4XkjudaJ2XsjXf7vvaz4oc364tMoa',
    volume: 101854.75172209999,
  },
  {
    address: 'mii3BBPNaL4PoQYGwboFBPCq2VyWN4DDMw',
    volume: 77800.94,
  },
  {
    address: 'tb1qszd924pprhxn9q3usc200xlmwsj8245c473stq',
    volume: 76987.80250705003,
  },
  {
    address: 'tbnb1xl5mgs2l0tlzwjy9fpt3v0nauncwpzmnnl6d6l',
    volume: 31204.74978236,
  },
  {
    address: 'tbnb1w3eqrazlg9zd99l4jyttj8qyszrkrane2g8au8',
    volume: 11205.14978197,
  },
  {
    address: '2N1ZLS8ePzMCtDY9QZBAk3G5LyvbMf5J9nu',
    volume: 3736.38339,
  },
  {
    address: 'mjZ7Qbo3s6H1jvu3yo6Y75HK3DawzbPhAv',
    volume: 3431.92024211,
  },
  {
    address: 'mwHvpLswinucSRUHKZeoi2pLuGKjv6EWS8',
    volume: 2371.3309037299987,
  },
  {
    address: 'tbnb1hzmm62lape793rju0dek5ecr83qlh6q608uuzn',
    volume: 2365.696425859999,
  },
  {
    address: 'tbnb1d5cmd25sjff858d70r0vy7g7mrumrpf9c494tt',
    volume: 2106.169673859999,
  },
  {
    address: 'mvhBkh9QiVwCW5eK3MBB8AyBZcMfRTcmwa',
    volume: 2080.2397210999993,
  },
  {
    address: 'tbnb1yj5pv0plj6qkuwx6ejgk4z3ux2eytuaz3xl7c7',
    volume: 858.1094884199998,
  },
  {
    address: 'tb1q075vmsjn3r8g8dtytkhxrhpkxt4k5rsw4aezvc',
    volume: 639,
  },
  {
    address: 'tb1qk9egnkfam7s4xknvs2ytmlght834pq99lcww93',
    volume: 500.05959232,
  },
  {
    address: 'tb1qjs7ewazkl7ssq228xajrzuvjaykmc07qyxwxpv',
    volume: 498,
  },
  {
    address: 'tb1q2vwn0wguh8xl3e8pzyal77gswjumgcm59gzstx',
    volume: 454.01363355999996,
  },
  {
    address: 'tbnb1kke40z65a5l9fepulk2f9am5y36nwn687cl9z4',
    volume: 429.99997664999995,
  },
  {
    address: 'tbnb1967h47m8qvlvfpamqhaxxs8xfv05u6n92ucud7',
    volume: 407.04514542000004,
  },
  {
    address: 'tb1qjd00y60grlekv5wggnvheuv0576gvcdukdan5n',
    volume: 360,
  },
  {
    address: 'tbnb1z20t7rn6urh46m2tavny3ap9n0pvkf47mynuza',
    volume: 300.21276043,
  },
  {
    address: 'tb1q83rgnzkckp9v2vg4xsvw6w569s257zlwag29u7',
    volume: 280,
  },
  {
    address: 'tb1qshnwpkrad4e4dvxe3xvu8agytccuxclxm3pg4q',
    volume: 279,
  },
  {
    address: 'tbnb1u37ysv75tv8ehjw700nwnu263edde5nrw0l548',
    volume: 269.65258683,
  },
  {
    address: 'tb1q0nyz3he0ag6xa945spcrvfk7g8m98ky4752csw',
    volume: 150,
  },
  {
    address: 'n4pnr8Lg7nEzLmxLjEnptT9JW1cx5vSyUf',
    volume: 150,
  },
  {
    address: 'tb1qnyl2ljvvzxylgrk2ltlk0h0j090yu8z0yh4t6e',
    volume: 143.6,
  },
  {
    address: 'tb1q4s7pam6mkcpndv6s0hdae6e5s5gph3a289f8yr',
    volume: 122.88,
  },
  {
    address: 'tb1qslqsrx3emdz66v6mflqn5y3havct67p62xt2kr',
    volume: 122.88,
  },
  {
    address: 'tb1qsyhf7y6j2thdhvw4q87lv6lsds54gq7yhq6llg',
    volume: 122,
  },
  {
    address: 'tb1qpcwve9e862qlqvuwcm9plrhcllv38wzda7fdwu',
    volume: 122,
  },
  {
    address: 'tb1q2xc94n35pdtvrj3nsk8nx95yv9m9rr3jdlpm74',
    volume: 120,
  },
  {
    address: 'tb1qgvdhzv9w52j3mcfxyxefe43kmnx0wm5v753nqt',
    volume: 102,
  },
  {
    address: 'tb1qhmj2puw4002fzqewlg6rhgs57aymw2087qs6gd',
    volume: 100.55,
  },
  {
    address: 'tbnb1vzyywc3y0q0jy3smtd5nwlatmdzaxh2t8e9mhu',
    volume: 99.99999378,
  },
  {
    address: 'tb1qp9dj5ugqlflgk9dc93ykk0zk8ltlwnl7tqnqur',
    volume: 99.7995003,
  },
  {
    address: 'mp9XbezaL6C5j3RVMGhGNmyFKaCgyGZJY3',
    volume: 99,
  },
  {
    address: 'tb1q06r5wtt6vnvdcusj2v7ykcu6cmnrgtswk3sn5w',
    volume: 90,
  },
  {
    address: 'tbnb1dedxffvl324ggfdpxl0gw5hwylc848ztuy7g7c',
    volume: 60.539980699999994,
  },
  {
    address: 'tb1q2szcxdzdgy4war356es5ewuaqn2k500nrk02fh',
    volume: 45,
  },
  {
    address: 'tb1qvxzckxfs8x3ulqz6fvurcwscwlvrgwmzsqc3n8',
    volume: 28,
  },
  {
    address: 'mnEb1XotVFwF6SuUzFSohb5XSkmFEuJNS5',
    volume: 21,
  },
  {
    address: 'tb1q4e8np0xp25ea3g09ycd0zd97ck46qhjslu4m36',
    volume: 19.11773889,
  },
  {
    address: 'tb1q5suynwczzes3uv0eth69wnrp75yan6jj39394n',
    volume: 17.519983160000006,
  },
  {
    address: 'tb1qnuj9pc78myyqdt6290vpw5awr82yge4tk98eg6',
    volume: 5.33915038,
  },
  {
    address: 'tb1qvanwf6t9hdqmwwykewpvsfvwsvapy0d34tvfzg',
    volume: 5,
  },
  {
    address: 'tb1qjf2sl6tdnv9y86fa9lfqkzn9pzmv8r4ywk6k7l',
    volume: 4.60539203,
  },
  {
    address: 'tbnb1gls26vjjqqjw7wfgs07707yr58lc8z8sxml4hk',
    volume: 4.5999931,
  },
  {
    address: 'tb1qgv6n6fm76ul3m2uk4klykzys5lsh7zu4lee5ms',
    volume: 4.0953881,
  },
  {
    address: 'mqAnLKMMbjcuyJJMesWVDHH2yGtCM3CFRk',
    volume: 4,
  },
  {
    address: 'tb1qhcy263n3w9rfzdkmjrxysd063cf28rka3rm67k',
    volume: 1,
  },
  {
    address: 'tb1qule573dacksmuvrpvmdlr34f8a628h4kfqlpjn',
    volume: 1,
  },
  {
    address: 'tb1qpl9upue4q96ckd63s2e35e7hd5mqjpkj4hvrcg',
    volume: 1,
  },
  {
    address: 'tbnb1qv8vu2kf7340cq0rgjqqfjfsf2825z3kw2d753',
    volume: 0.99999381,
  },
  {
    address: 'tbnb1sqatyjwldqu0a0mwgeq2g0cppaxl8jw5k2gmtg',
    volume: 0.79999491,
  },
  {
    address: 'tb1q3lxmx22z628dfh0zj2e8z0cjtlvumg2t2n8gm7',
    volume: 0.13331388,
  },
  {
    address: 'tb1qpkptqcu0f6zawxrps8z02gzclz6c3mcnjr9rhk',
    volume: 0.13,
  },
  {
    address: 'tb1qs6ezatmxmgekm3098hlj5xwp96h78v5hqfn6ly',
    volume: 0.1,
  },
  {
    address: 'tbnb18y6ak4nvd7u89dsyu205jhwaguluxt9l7fklsz',
    volume: 0.024012320000000007,
  },
  {
    address: '2N9FnYDwJfKBrHswpMNsdrh9HDCMsFHcTgu',
    volume: 0.01,
  },
  {
    address: 'tb1qgan4yfkcr89amr78h56eva9y8lpv7mu33x9yj5',
    volume: 0.01,
  },
  {
    address: 'tbnb1den93t8yyfa6za40hkwwxjy93a6fc299ac9z5t',
    volume: 0.00199292,
  },
  {
    address: 'tbnb1qg00nl4jk3lngjvsrsrfgxj54285lg84gzwz0s',
    volume: 0.00049341,
  },
];
