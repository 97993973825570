import React, { useEffect, useState } from 'react';
import { RankingResult } from '../data/rankingResult';
import { RankingObjectArray } from '../types/camp';
import { get } from '../utils/apiClient';
import Campaign from './Campaign';
import Footer from './Footer';
import FooterPush from './FooterPush';

const Main = () => {
  // const now = new Date().getTime() / 1000;
  // const cutOffTime = now - 60 * 60 * 24 * 42;

  // States
  const [btcPrice, setBtcPrice] = useState(0);
  // const [totalVolume, setTotalVolume] = useState(0);
  // const [tradesCount, setTradesCount] = useState(0);
  // const [ranking, setRanking] = useState<RankingObjectArray>([]);

  // Memo: This is final result data of ranking. So no need to fetch from server now
  const ranking: RankingObjectArray = RankingResult;
  const tradesCount = 4997;
  const totalVolume = 1341659;

  /**
   * Fetches BTC price in USD.
   */
  const getBtcPrice = async (): Promise<number> => {
    const url = 'https://blockchain.info/ticker';
    const res = await get(url);
    // @ts-ignore
    return res.parsedBody.USD['15m'];
  };

  // /**
  //  * Fetches all the transaction data during a specified term (default: 30days)
  //  */
  // const getTransactionData = async () => {
  //   let transactionData: SwapRawObjectArray = [];
  //   let shouldContinue = true;

  //   for (let i = 0; shouldContinue; i++) {
  //     const url = `${API_ENDPOINT}/swaps/query?page=${i}&page_size=${PAGE_SIZE}&status=COMPLETED`;
  //     const res = await get(url);
  //     // @ts-ignore
  //     const txs: SwapRawObjectArray = res.parsedBody.items;

  //     // NOTE: If this is the last page, then the for-loop should stop.
  //     if (txs.length === PAGE_SIZE) {
  //       //@ts-ignore
  //       shouldContinue = checkIsValidPeriod(txs[txs.length - 1].timestamp);
  //     } else {
  //       shouldContinue = false;
  //     }

  //     // NOTE: BTC-in and BTC-out are now considered.
  //     const filteredTxs = txs.filter((tx: SwapRawObject, idx, self) => {
  //       if (tx.timestamp) {
  //         return (
  //           checkIsValidPeriod(tx.timestamp) &&
  //           self.findIndex((_tx) => _tx.txIdIn === tx.txIdIn) === idx // unique txIdIn
  //         );
  //       }
  //       return false;
  //     });
  //     transactionData = [...transactionData, ...filteredTxs];
  //   }
  //   return transactionData;
  // };

  // /**
  //  * Calculates the transaction data to display ranking.
  //  * @param txs
  //  */
  // const calcRanking = (txs: SwapRawObjectArray) => {
  //   let tmpTotalVolume = 0;
  //   txs.forEach((tx: SwapRawObject) => {
  //     // NOTE: If BTC/BTC.B rate changes, this will need to consider the rate.
  //     tmpTotalVolume += Number(tx.amountIn);
  //   });
  //   setTotalVolume(tmpTotalVolume);
  //   setTradesCount(txs.length);

  //   const addressLookUpTable: string[] = [];
  //   let tmpRanking: RankingObjectArray = [];
  //   txs.forEach((tx: SwapRawObject) => {
  //     if (addressLookUpTable.includes(tx.addressOut)) {
  //       tmpRanking = tmpRanking.map((item) => {
  //         if (item.address === tx.addressOut) {
  //           return {
  //             address: tx.addressOut,
  //             volume: Number(item.volume) + Number(tx.amountIn),
  //           };
  //         } else {
  //           return item;
  //         }
  //       });
  //     } else {
  //       const item: RankingObject = {
  //         address: tx.addressOut,
  //         volume: Number(tx.amountIn),
  //       };
  //       addressLookUpTable.push(tx.addressOut);
  //       tmpRanking.push(item);
  //     }
  //   });

  //   tmpRanking.sort((a: RankingObject, b: RankingObject) => {
  //     if (a.volume > b.volume) {
  //       return -1;
  //     } else {
  //       return 1;
  //     }
  //   });
  //   setRanking(tmpRanking);
  // };

  useEffect(() => {
    getBtcPrice().then((price) => {
      setBtcPrice(price);
    });

    // getTransactionData().then((res: SwapRawObjectArray) => {
    //   calcRanking(res);
    // });
  }, []);

  return (
    <>
      <Campaign
        participants={ranking.length}
        trades={tradesCount}
        volume={totalVolume}
        ranking={ranking}
        btcPrice={btcPrice}
      />
      <FooterPush />
      <Footer />
    </>
  );
};

export default Main;
