import { theme } from "./theme";
import styled from "styled-components";

export const WinSpan = styled.span`
  font-weight: bold;
  font-size: 32px;
  color: ${theme.colors.gold};
`;

export const PrizeSpan = styled(WinSpan)`
  color: ${theme.colors.white};
`;
