import React from "react";
import { HeaderContainer } from "../styles/customDiv";
import { SwingbyDocs, Skybridge } from "../constants";

interface Props {}

const Header: React.FC<Props> = () => {
  return (
    <HeaderContainer>
      <p>
        <a href={Skybridge} rel="noopener noreferrer" target="_blank">
          Go to Skybridge App (testnet)
        </a>
      </p>
      <p>
        <a href={SwingbyDocs} rel="noopener noreferrer" target="_blank">
          What is Swingby?
        </a>
      </p>
    </HeaderContainer>
  );
};

export default Header;
