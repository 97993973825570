import React from "react";
import styled from "styled-components";
import { theme } from "../styles/theme";
import { customMedia } from "../styles/globalStyle";

const Appreciation = () => {
  return (
    <AppreciationContainer>
      <div className='wrapper'>
        <h1>
          THANK YOU FOR PARTICIPATING!!!{" "}
          <span role='img' aria-label='congrats'>
            🎉
          </span>
        </h1>
        <h3>Competition has now ended</h3>
      </div>
    </AppreciationContainer>
  );
};

export default Appreciation;

const AppreciationContainer = styled.div`
  position: absolute;
  color: ${theme.colors.white};
  top: 44rem;
  display: flex;
  justify-content: center;
  ${customMedia.lessThan("laptop")`
      width: 50rem;
    `}
  ${customMedia.lessThan("tablet")`
      width: 36rem;
    `}
  ${customMedia.lessThan("mobile")`
      width: 100%;
      top: 41rem;
    `}

  .wrapper {
    width: 66rem;
    height: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 2px solid ${theme.colors.white};
    background-color: ${theme.colors.blackTransparent};
    ${customMedia.lessThan("laptop")`
      width: 100%;
    `}
    ${customMedia.lessThan("mobile")`
      width: 90%;
    `}
    h1 {
      font-family: Avenir;
      font-weight: 900;
      font-size: 40px;
      ${customMedia.lessThan("laptop")`
        font-size: 30px;
      `}
      ${customMedia.lessThan("tablet")`
        font-size: 24px;
      `}
    }
    h3 {
      font-family: Avenir;
      font-size: 30px;
      ${customMedia.lessThan("laptop")`
        font-size: 20px;
      `}
      ${customMedia.lessThan("tablet")`
        font-size: 20px;
      `}
    }
  }
`;
