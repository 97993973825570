import { theme } from "./theme";
import styled from "styled-components";
import { customMedia } from "./globalStyle";

export const StatsAmountP = styled.p`
  font-weight: bold;
  font-size: 52px;
  color: ${theme.colors.lightGold};
  margin-bottom: 0;
`;

export const StatsTitleP = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  color: ${theme.colors.periwinkle};
`;

export const AddressP = styled.p`
  ${customMedia.greaterThan("largeDesktop")`
      text-align: left;
      margin-left: 20%;
  `}
  ${customMedia.between("largeTablet", "largeDesktop")`
      text-align: left;
      margin-left: 12%;
  `}
  ${customMedia.lessThan("desktop")`
    margin-left: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 20px;
  `}
  ${customMedia.lessThan("largeTablet")`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 20px;
  `}
`;
