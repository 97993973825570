import React from 'react';
import styled from 'styled-components';
import { Links, Media, Support } from '../constants';
import footerLogo from '../resources/FOOTER-LOGO.svg';
import { customMedia } from '../styles/globalStyle';
import { theme } from '../styles/theme';

interface Props {}

const currentYear = new Date().getFullYear();

const Footer: React.FC<Props> = (props: Props) => {
  return (
    <FooterContainer>
      <div className='wrapper'>
        <ul className='links'>
          {Links.map((link) => (
            <li key={link.description}>
              <a href={link.link} rel='noopener noreferrer' target='_blank'>
                {link.description}
              </a>
            </li>
          ))}
          <li>
            <a
              href={`mailto:${Support}`}
              rel='noopener noreferrer'
              target='_blank'>
              Support
            </a>
          </li>
        </ul>

        <div className='logo'>
          <div className='swingby-logo'>
            <img src={footerLogo} alt='footer-logo' />
          </div>
          <div className='media'>
            {Media.map((media) => (
              <a
                href={media.link}
                rel='noopener noreferrer'
                target='_blank'
                key={media.alt}>
                <img src={media.src} alt={media.alt} />
              </a>
            ))}
          </div>
        </div>
        <footer>© 2018-{currentYear} Swingby Labs. All Rights Reserved.</footer>
      </div>
    </FooterContainer>
  );
};
export default Footer;

const FooterContainer = styled.div`
  .wrapper {
    margin-top: auto;
    background-color: ${theme.colors.footerTransparent};
    grid-template-columns: 1fr;
    display: grid;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-right: 80px;
    padding-left: 80px;
    color: ${theme.colors.white};
    ${customMedia.lessThan('tablet')`
    padding: 20px;
    `};
    ${customMedia.lessThan('mobile')`
      grid-template-columns: 1fr;
      justify-content: center;
      justify-items: center;
      height: 100%;
    `}
    ${customMedia.greaterThan('mobile')`
      height: 190px;
    `}
  }
  .wrapper ul {
    display: flex;
    justify-content: space-between;
    width: 394px;
    list-style: none;
    ${customMedia.greaterThan('mobile')`
      padding: 0;
    `}
    ${customMedia.lessThan('mobile')`
      display: grid;
      width: 334px;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      padding-left: 0px;
      margin: 0px;
    `}
  }
  .wrapper li {
    ${customMedia.lessThan('mobile')`
      justify-items: center;
      justify-self: center;
      line-height: 30px;
    `}
  }

  .wrapper a {
    font-size: 14px;
    color: ${theme.colors.white};
  }

  .logo {
    display: flex;
    justify-content: space-between;
    ${customMedia.lessThan('mobile')`
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
    `}
    .swingby-logo {
      margin-top: 22px;
      width: 150px;
      ${customMedia.lessThan('mobile')`
        height: 26px;
        margin-bottom: 6px;
        text-align: center;
      `}
      img {
        height: 26px;
      }
    }
  }
  .media {
    display: grid;
    grid-template-columns: repeat(4, 30px);
    align-items: center;
    grid-column-gap: 30px;
    ${customMedia.lessThan('mobile')`
      grid-template-columns:repeat(4, 1fr);
      grid-column-gap: 10px;
      justify-items: center;
    `}
    img {
      height: 28px;
      width: 28px;
      cursor: pointer;
    }
  }

  footer {
    margin-top: 24px;
    font-weight: 300;
    font-size: 14px;
  }
`;
