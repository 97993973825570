import React from "react";
import { ToastProvider } from "react-toast-notifications";
import "./App.css";
import Main from "./components/Main";
import { GlobalStyle } from "./styles/globalStyle";

const App: React.FC = () => {
  return (
    <>
      <ToastProvider>
        <Main />
        <GlobalStyle />
      </ToastProvider>
    </>
  );
};

export default App;
