import { theme } from "./theme";
import styled from "styled-components";
import { customMedia } from "./globalStyle";

export const CampaignContainer = styled.div`
  .wrapper {
    padding: 0 8%;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    ${customMedia.lessThan("laptop")`
      padding: 0% 4%;
    `}
    ${customMedia.lessThan("tablet")`
      padding: 0%;
    `}
    .container {
      background-color: ${theme.colors.darkTransparent};
      width: 100%;
      padding: 2%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .title-row {
        display: flex;
        flex-flow: column;
        text-align: center;
        color: ${theme.colors.white};
        margin-top: 0px;
        p {
          margin-top: -14px;
          font-weight: 800;
          font-size: 20px;
          ${customMedia.lessThan("mobile")`
            font-size: 14px;
          `}
        }
        .title {
          width: auto;
          display: grid;
          width: 540px;
          grid-template-columns: repeat(10, 1fr);
          grid-column: 1/-1;
          justify-items: center;
          ${customMedia.lessThan("mobile")`
            width: 360px;
          `}
          .left-decoration {
            grid-column: 1/2;
            height: 140px;
            align-self: end;
            ${customMedia.lessThan("mobile")`
              height: 100px;
            `}
          }
          .trophy {
            grid-column: 3/5;
            align-self: center;
            margin-right: 50px;
            margin-left: -50px;
            ${customMedia.lessThan("mobile")`
              height: 60px;
            `}
          }
          h1 {
            grid-column: 5/8;
            font-size: 100px;
            font-weight: bold;
            margin-bottom: 22px;
            ${customMedia.lessThan("mobile")`
              font-size: 70px;
            `}
          }
          .right-decoration {
            grid-column: 10/10;
            height: 140px;
            align-self: end;
            ${customMedia.lessThan("mobile")`
              height: 100px;
            `}
          }
        }
      }
      .prize {
        align-items: center;
        margin-top: 40px;
        width: 320px;
        text-align: center;

        h1 {
          margin-top: 0px;
          margin-bottom: 80px;
          color: ${theme.colors.white};
          font-weight: 800;
          font-size: 38px;
          border-bottom: ridge;
          padding-bottom: 2px;
          text-align: center;
          cursor: pointer;
        }

        .swap-coin {
          margin-bottom: 50px;
          height: 100px;
          ${customMedia.lessThan("mobile")`
            height: 80px;
          `}
        }

        .line {
          margin-top: -170px;
        }
        .rank {
          margin-top: 30px;
          display: flex;
          flex-flow: column;
          text-align: center;

          .rank-prize {
            padding-left: 30%;
            text-align: left;
            width: 300px;
            margin-bottom: 20px;
          }
          p {
            color: ${theme.colors.white};
            font-size: 20px;
          }
        }
      }
      .stats-row {
        text-align: center;
        color: ${theme.colors.white};
        margin-top: 40px;
        width: 100%;
        h2 {
          font-weight: 800;
          font-size: 40px;
          margin-bottom: 22px;
        }
        span {
          font-size: 20px;
        }
        .stats-container {
          width: 100%;
          margin-top: 30px;
          display: flex;
          flex-flow: row;
          justify-content: space-evenly;
          ${customMedia.lessThan("laptop")`
            flex-flow: column;
            text-align: center;
          `}

          .stats-column {
            width: 280px;
            white-space: nowrap;
            ${customMedia.lessThan("laptop")`
              width: 100%;
              margin-bottom: 40px;
            `}
            .usd {
              font-size: 22px;
              margin-top: 8px;
            }
            .stats-column-center {
              display: flex;
              justify-content: space-between;
              align-items: center;
              ${customMedia.lessThan("laptop")`
                justify-content: space-evenly;
              `}
              .bar {
                height: 42px;
                ${customMedia.lessThan("laptop")`
                  display: none;
                `}
              }
            }
          }
        }
      }
    }
  }
`;

const gridTemplateColumnsFrame = "5% 40% 10% 20% 5% 20%";
const gridTemplateColumnsFrameMobile = "10% 29% 0% 28% 3% 28%";

export const LeaderBoardContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 80px;
  padding: 0 4%;
  margin-bottom: 50px;

  ${customMedia.lessThan("laptop")`
    margin-top: 32px;
  `}

  h2 {
    font-weight: 800;
    font-size: 40px;
    color: ${theme.colors.white};
  }
  .list {
    margin-top: 40px;
    width: 100%;
    background-color: ${theme.colors.white};
    border-radius: 10px;

    .pagination {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 36px;
      font-weight: 800;
      color: ${theme.colors.navy};
      ${customMedia.lessThan("tablet")`
        padding: 20px;
      `}
      .forward-icon {
        cursor: pointer;
        margin-left: 8px;
      }
    }

    .description {
      display: grid;
      grid-template-columns: ${gridTemplateColumnsFrame};
      padding: 22px 10px;
      align-items: center;
      border: 1px solid ${theme.colors.rat};
      ${customMedia.lessThan("tablet")`
      padding: 0px 20px;
      `}
      ${customMedia.lessThan("mobile")`
      padding: 0px 6px;
      grid-template-columns: ${gridTemplateColumnsFrameMobile};
      font-size: 10px;
      `}
      p {
        font-weight: 500;
        font-size: 14px;
        padding: 8px 0;
        margin-bottom: 0;
        color: ${theme.colors.lightPurple};
        ${customMedia.greaterThan("laptop")`
          font-size: 16px;
        `}
      }
    }
    .listing {
      p {
        font-weight: 800;
        color: ${theme.colors.navy};
        ${customMedia.lessThan("mobile")`
          font-weight: 600;
        `}
      }
      .crown {
        position: relative;
        display: flex;
        justify-content: center;
        p {
          position: absolute;
        }
        img {
          height: 34px;
          ${customMedia.lessThan("laptop")`
            width: 30px;
          `}
          ${customMedia.lessThan("tablet")`
            width: 28px;
          `}
          ${customMedia.lessThan("mobile")`
            width: 22px;
          `}
        }
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 94%;
  display: flex;
  justify-content: space-between;
  ${customMedia.lessThan("mobile")`
    flex-flow: column;
    text-align: center;
  `}
  p {
    margin-top: 20px;
    margin-bottom: 50px;
    color: ${theme.colors.white};
    font-weight: 900;
    font-size: 22px;
    border-bottom: ridge;
    padding-bottom: 2px;
    ${customMedia.lessThan("tablet")`
      font-weight: 700;
      font-size: 20px;
    `}
    ${customMedia.lessThan("mobile")`
      flex-flow: column;
      align-self: center;
      margin-bottom: 14px;
    `}

    a {
      color: ${theme.colors.white};
      text-decoration: none;
    }
  }
`;

export const RuleContainer = styled.div`
  .wrapper {
    margin-top: 60px;
    margin-bottom: 60px;
    display: grid;
    justify-items: center;
    h2 {
      font-size: 40px;
      font-weight: 800;
      text-align: center;
      color: ${theme.colors.white};
      ${customMedia.lessThan("desktop")`
        font-size: 34px;
        margin-bottom: 30px;
      `}
    }
    .container {
      width: 70%;
      background-color: transparent;
      ${customMedia.lessThan("desktop")`
        width:90%;
      `}
      ${customMedia.lessThan("mobile")`
        width:96%;
      `}
      p {
        color: ${theme.colors.white};
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;

        ${customMedia.lessThan("mobile")`
          font-size: 14px;
        `}
      }
      a {
        color: ${theme.colors.carolinaBlue};
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        ${customMedia.lessThan("mobile")`
          font-size: 16px;
        `}
      }
      .hash {
        color: ${theme.colors.mayaBlue};
        font-weight: 500;
        font-size: 22px;
        ${customMedia.lessThan("mobile")`
          font-size: 16px;
        `}
      }
      .bonus-title {
        font-weight: 900;
        text-align: center !important;
        margin-bottom: 8px;
      }
      .guide {
        margin: 30px 0;
      }
      .bonus {
        background-color: ${theme.colors.footerTransparent};
        padding: 20px;
        border-radius: 14px;
        text-align: justify;
        text-justify: inter-word;
        ${customMedia.lessThan("mobile")`
          padding: 16px;
        `}
      }
      .join-step {
        .to-receive {
          margin: 20px 0;
        }
        .step {
          margin: 40px 0;
          padding-left: 20px;
          ${customMedia.lessThan("mobile")`
            padding-bottom: 10px;
            padding-left: 10px;
          `}
          .br {
            ${customMedia.greaterThan("mobile")`
              display: none;
            `}
          }
        }
      }
      .guide-line {
        padding-bottom: 20px;
        text-align: justify;
        text-justify: inter-word;
        ${customMedia.lessThan("mobile")`
          padding-bottom: 10px;
        `}
        .faucet-link {
          color: ${theme.colors.carolinaBlue};
          font-weight: 500;
          font-size: 20px;
          border-bottom: ridge;
          padding-bottom: 2px;
          ${customMedia.lessThan("mobile")`
            font-weight: 500;
            font-size: 14px;
          `}
        }
      }
    }
  }
`;
