import { createGlobalStyle } from "styled-components";
import { generateMedia } from "styled-media-query";
import bg from "../resources/BACKGROUND.svg";
import { theme } from "./theme";

export const customMedia = generateMedia({
  largeDesktop: "1700px",
  desktop: "1296px",
  laptop: "1172px",
  largeTablet: "960px",
  tablet: "840px",
  mobile: "540px"
});

export const GlobalStyle = createGlobalStyle`

* {
  box-sizing: border-box;
}
*:focus {
outline: none;
}

/* Memo: To make footer attached to the bottom  */
/* Need to define 'flex: 1;' in the div above the footer  */
#root{
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  /*fallback*/
  background-image: url(${bg});
  background-image: url(${bg}), linear-gradient(130.82deg, #413D8E 30.91%, #DA9273 90.88%);
  background-attachment: fixed;
  background-size: auto;
  background-color: ${theme.colors.navy};
  position: relative;
  padding: 0;
  margin: 0;
  font-style: normal;
  height: 100%;
}

/* background color when open the modal */
.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  background-color: ${theme.colors.darkTransparent};
}

.ui.active.modal {
  width: ${theme.modalWidth};
  height: ${theme.modalHeight};
  border-radius: 38px;
  padding: auto;
  ${customMedia.lessThan("mobile")`
    width: ${theme.modalWidthMobile};
    height: ${theme.modalHeight};
  `}
  }
`;
